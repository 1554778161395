"use client";
import { useAuth } from "@hooks/useAuth";
import {
  UserExistData,
  UserExistParams,
  checkUserExistMutationKey,
} from "@queries/checkUserExist";
import { GetUserKey, getUser } from "@queries/getUser";
import { useQueryClient } from "@tanstack/react-query";
import { getItem } from "@utils/localStorage";
import { userLogout } from "@utils/userLogout";
import { AxiosResponse } from "axios";
import dynamic from "next/dynamic";
import { usePathname, useSearchParams } from "next/navigation";
import React from "react";

const UnauthenticatedLayout = dynamic(
  () => import("@components/UnauthenticatedLayout"),
);

const steps: Record<
  string /* figure out how to use types from router  */,
  { title: string; titleExistingUser?: string; step: number }
> = {
  "/login": {
    title: "Sign Up or Log in",
    step: 1,
  },
  "/enter-number": {
    title: "Sign Up",
    step: 3,
  },
  "/verification": {
    title: "Sign Up",
    titleExistingUser: "Sign In",
    step: 2,
  },
  "/phone-verification": {
    title: "Verify phone number",
    step: 4,
  },
  "/select-name": {
    // title: 'Pick a name',
    title: "Sign up or Log in",
    step: 5,
  },
  "/enter-name": {
    // title: 'Pick a name',
    title: "Sign up or Log in",
    step: 6,
  },
};

const UnAuthenticatedLayout: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const auth = useAuth();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const queryClient = useQueryClient();

  const access_token = getItem("access_token");

  if (access_token && !auth.isAuthenticated) {
    getUser()
      .then((res) => {
        queryClient.setQueryData(GetUserKey, res);

        auth.setUser(res.data);
      })
      .catch((err) => {
        console.error(err);
        userLogout();

        typeof window !== "undefined" &&
          window?.location?.replace(
            `/?redirect=${encodeURIComponent(pathname)}`,
          );
      });
  }

  if (auth.isAuthenticated) {
    typeof window !== "undefined" &&
      window?.location?.replace(searchParams.get("redirect") ?? "/");
  }

  const userExistMutation = queryClient
    .getMutationCache()
    .find<AxiosResponse<UserExistData, unknown>, unknown, UserExistParams>({
      mutationKey: checkUserExistMutationKey,
      status: "success",
      predicate(mutation) {
        return mutation.state.variables.value === getItem("email");
      },
    });
  let userExist: boolean = false;

  if (userExistMutation) {
    userExist = userExistMutation.state.data?.data.data.user_in_system ?? false;
  }

  const step = steps[pathname];

  if (step && step["titleExistingUser"] && userExist) {
    step.title = step.titleExistingUser;
  }

  return <UnauthenticatedLayout step={step}>{children}</UnauthenticatedLayout>;
};

export default UnAuthenticatedLayout;
